import React from "react";
import '../../css/components/_disclaimer.scss';

const Disclaimer = () => {
    return <div className="disclaimer-container" data-datocms-noindex>The content on healthtimes.co.uk is for informational and educational 
           purposes only and should not be construedas professional medical advice or guidance. Should you need professional medical advice 
           or guidance, you should consult with such a professional in their relevant field. Likewise, you should always seek professional 
           medical advice before starting a diet, exercise regime or course of medication, or introducing or eliminating specific elements 
           from your lifestyle. We strive to write accurate, genuine and helpful content, and all views and opinions expressed within this 
           article are specifically the views of the author.</div>
}

export default Disclaimer;